import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://352bf211018167824d77820db8ffe512@o556743.ingest.sentry.io/4506578883444736",
  release: "1.0.0",
  environment: "production",
  sampleRate: 0.5,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
  debug: false,
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name === "/home") {
      return 0.5;
    }
    return 0.1;
  },
});
